import { createStorage, sessionStorageMethods, TimeUnit } from '/storage';

export const snackbarStoragePrefix = 'snackbar:';
export const snackbarStorageKey = 'history';
export const SNACKBAR_STORAGE_MAX_SIZE = 75;

export const snackbarStorage = createStorage({
  prefix: snackbarStoragePrefix,
  storage: sessionStorageMethods,
  timeUnit: TimeUnit.Hour,
  ttl: 24,
});
