import { createStorage, sessionStorageMethods, TimeUnit } from '/storage';

export const pageTitlePrefix = 'PageTitle:';
export const pageTitleKey = 'Current';
export const pageLink = 'Link';

export const pageTitleStorage = createStorage({
  prefix: pageTitlePrefix,
  storage: sessionStorageMethods,
  timeUnit: TimeUnit.Hour,
  ttl: 1,
});
