import { DateTime } from 'luxon';

export function formatDate(date: Date | string, format = 'EEE DDD t'): string {
  const luxon = date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromISO(date);
  return luxon.toLocal().toFormat(format);
}

export function formatISODate(date: Date | string): string {
  const luxon = date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromISO(date, { setZone: true });
  return luxon.toISO() ?? '';
}

export function formatUTCDate(date: Date | string, format = 'EEE DDD t'): string {
  const luxon = date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromISO(date);
  return luxon.toUTC().toFormat(format);
}

/**
 * Formats a given date in a "friendly" manner, providing a more human-readable
 * output compared to the default `formatDate` function.
 *
 * - If the date is today, it returns "Today at HH:mm:ss".
 * - If the date was yesterday, it returns "Yesterday at HH:mm:ss".
 * - For other dates, it defaults to the provided format (full weekday name with time).
 *
 * This function is designed to enhance user experience by offering context (e.g., "Today" or "Yesterday")
 * for recent dates, rather than always showing a fixed date format.
 *
 * @param {Date | string} date - The date to be formatted, which can be a `Date` object or an ISO string.
 * @param {string} [format="cccc 'at' HH:mm:ss"] - The fallback format to use when the date is neither today nor yesterday.
 * @returns {string} A string representation of the date in a friendly format.
 */
export function formatFriendlyDate(date: Date | string, format = "cccc 'at' HH:mm:ss"): string {
  const luxon = date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromISO(date);
  const now = DateTime.now();

  if (luxon.hasSame(now, 'day')) {
    return luxon.toFormat("'Today at' HH:mm:ss");
  } else if (luxon.hasSame(now.minus({ days: 1 }), 'day')) {
    return luxon.toFormat("'Yesterday at' HH:mm:ss");
  }

  // Default formatting: Full weekday name with time
  return luxon.toFormat(format);
}
