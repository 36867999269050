import { FC, ReactNode } from 'react';
import { CardHeader, Dialog, DialogActions, DialogContent } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';

import { mergeClasses } from '/helpers/mergeClasses';

import { useDialogStyles } from './styles';

interface ISelectDialogClasses extends Record<string, string | undefined> {
  dialogHeader?: string;
  dialogActions?: string;
  dialogHeaderAction?: string;
  dialogContent?: string;
}

interface ISelectDialogProps extends Omit<DialogProps, 'title' | 'classes' | 'children' | 'content'> {
  title: string | ReactNode;
  subtitle?: string;
  content: ReactNode;
  actions?: ReactNode;
  headerAction?: ReactNode;
  onClose: () => void;
  classes?: ISelectDialogClasses;
  fullWidth?: boolean;
}

export const SelectDialog: FC<ISelectDialogProps> = (props) => {
  const {
    title,
    subtitle,
    content,
    actions,
    headerAction,
    onClose,
    fullWidth = true,
    classes: propsClasses = {},
    ...dialogProps
  } = props;

  const innerClasses = useDialogStyles();
  const classes = mergeClasses(innerClasses, propsClasses);

  const handleClose = () => onClose();

  return (
    <Dialog
      aria-labelledby='simple-dialog-title'
      fullWidth={fullWidth}
      maxWidth='md'
      onClose={handleClose}
      {...dialogProps}
    >
      <CardHeader
        title={title}
        subheader={subtitle}
        action={headerAction}
        classes={{ root: classes.dialogHeader, action: classes.dialogHeaderAction }}
      />
      <DialogContent className={classes.dialogContent}>{content}</DialogContent>
      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      {actions && <DialogActions className={classes.dialogActions}>{actions}</DialogActions>}
    </Dialog>
  );
};
